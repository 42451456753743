import axios from "axios";

const WompiService = () => {

    const axiosInstance = axios.create({
        // baseURL: `https://api.meddipay.com/`, 
        baseURL: process.env.REACT_APP_URL_WOMPI,
    });


    const GetTransaction = async (transaction) => {

        const respose = await axiosInstance.get('transactions/'+transaction,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    

    return { GetTransaction }
}

export default WompiService;