import { useEffect, useRef, useState } from "react";
import { Col, Row, Overlay, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import TarsService from '../services/TarsService';

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)

const Clients = ({ goToChan, goToAgora }) => {

    const { GetRatesFees } = TarsService();
    const [rates, setRates] = useState({});


    const [treatmentAmount, setTreatmentAmount] = useState("0");
    const [treatmentTerm, setTreatmentTerm] = useState(3);
    const [fee, setFee] = useState("0");
    const [payment1, setPayment1] = useState("0");
    const [payment2, setPayment2] = useState("0");
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const target = useRef(null);

    useEffect(() => {
        document.title = "Para pacientes";
        //window.scrollTo(0, 0)

        GetRatesFees().then(res => {
            let data = JSON.parse(res.data.data).cache;
            setRates(data)
        })

    }, []);

    const calculate = () => {
        //= (P * i) / (1 - (1 + i) ^ (-n))
        // let rate = rates.MonthlyBankInterest / 100;
        // let feeTemp = parseInt((treatmentAmount * rate) / (1 - Math.pow(1 + rate, -treatmentTerm)))
        // setFee(new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(feeTemp))
        let rate1 = rates.MinMonthlyInterestRate / 100;
        if (rate1 === 0) {
            setPayment1(new Intl.NumberFormat('es-ES', { minimumFractionDigits: 0 }).format(parseInt(treatmentAmount) / treatmentTerm))
        }
        else {
            let payment1Temp = parseInt((treatmentAmount * rate1) / (1 - Math.pow(1 + rate1, -treatmentTerm)))
            setPayment1(new Intl.NumberFormat('es-ES', { minimumFractionDigits: 0 }).format(payment1Temp))
        }


        let rate2 = rates.MaxMonthlyInterestRate / 100;
        let payment2Temp = parseInt((treatmentAmount * rate2) / (1 - Math.pow(1 + rate2, -treatmentTerm)))
        setPayment2(new Intl.NumberFormat('es-ES', { minimumFractionDigits: 0 }).format(payment2Temp))


    }


    const goToOurAllies = () => {
        navigate("/ourallies");
        window.scrollTo(0, 0)
    }

    const goToFAQClients = () => {
        navigate("/faqclients");
        window.scrollTo(0, 0)
    }

    const handleTreatmentAmount1 = (event) => {

        setTreatmentAmount(event.target.value)
    }

    const handleTreatmentAmount2 = (event) => {
        let temp = event.target.value.replaceAll("$", "").replaceAll(".", "")

        if (temp > rates.MaxAmount) {
            setTreatmentAmount(rates.MaxAmount)
            event.target.value = rates.LabelMaxAmount
        }
        else {
            setTreatmentAmount(temp)
        }

    }

    const handleTerm = (event) => {

        if (event.target.value > rates.MaxTerm) {
            setTreatmentTerm(rates.MaxTerm)
            event.target.value = rates.MaxTerm
        }
        else {
            setTreatmentTerm(event.target.value)
        }






    }

    return (<>
        <section>
            <div className="container d-flex flex-wrap justify-content-center my-5">
                <div className="col-sm-5">
                    <div>
                        <p className="fs-header">¡Consigue tu cupo en tan solo <span className="text-mp-orange">3 pasos</span>!
                        </p>
                    </div>
                    <div className="lh-32 my-5 text-70">
                        <p>Solicitar un cupo 100% online es muy
                            simple, ¡no te llevara más de 5 minutos!</p>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <button className="mp-big-button bg-mp-primary" style={{ border: "none", marginBottom: "20px" }} onClick={goToChan}>SOLICITA TU CUPO</button>
                        </div>

                    </div>
                </div>
                <div className="col-sm-7 d-flex justify-content-center">
                    <img
                        className="img-fluid"
                        src="assets/images/img/image-6.svg"
                        alt="person"
                    />
                </div>
            </div>

            <div className="d-flex justify-content-center flex-wrap py-5">
                <Row style={{ width: "80%", textAlign: "center" }} >

                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: "50px" }} className="img-pasos">
                        <img
                            className="img-fluid"
                            src="assets/images/img/pasos.svg"
                            alt="pasos" />
                    </Col> */}

                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: "50px" }} className="img-pasos">
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}><button className="btn-pasos">1</button></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}><button className="btn-pasos">1</button></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}><button className="btn-pasos">1</button></Col>
                        </Row>
                    </Col> */}

                    <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ marginBottom: "50px" }}>

                        <button className="btn-pasos">1</button>


                        <p className="fs-2 fw-bold">Solicita tu cupo</p>
                        <p>Accede a nuestro <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_CHAN}>portal</a> y rellena un pequeño formulario con tus datos, al instante tendrás una respuesta a tu solicitud.</p>
                        <img
                            className="img-fluid"
                            src="assets/images/img/letter.svg"
                            alt="letter" />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ marginBottom: "50px" }}>
                        <button className="btn-pasos">2</button>
                        <p className="fs-2 fw-bold">Escoge el tratamiento</p>
                        <p>Una vez aprobado tu cupo, escoge el tratamiento que deseas realizar y acércate a alguno de nuestros aliados para solicitar un presupuesto del tratamiento. </p>
                        <span style={{ cursor: "pointer" }} onClick={goToOurAllies}><p className="fs-6 fw-bold">Conoce nuestros aliados <i className="bi bi-arrow-right-circle-fill" style={{ color: "#ff9e25" }}></i></p></span>
                        <img
                            className="img-fluid"
                            src="assets/images/img/hand.svg"
                            alt="hand" />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ marginBottom: "50px" }}>
                        <button className="btn-pasos">3</button>
                        <p className="fs-2 fw-bold">Obtén tu invitación</p>
                        <p>Solicita tu invitación al  aliado, accede a ésta por medio de nuestro portal, elige el  plan de pagos que mejor  se acomode a tu bolsillo y finaliza con la firma del contrato.</p>

                        <img
                            className="img-fluid"
                            src="assets/images/img/sms.svg"
                            alt="sms" />
                    </Col>





                </Row>
            </div>

            <div className="d-flex justify-content-center py-5" style={{ marginTop: "-50px" }}>
                <button className="mp-big-button btn-solid-orange" style={{ fontSize: "17px" }} onClick={goToOurAllies}>Conoce nuestros aliados</button>
            </div>
        </section>

        <section className="mx-4 my-5 px-5 py-1 bg-mp-gray rounded-pill">
            <div className="py-5 my-5">
                <p className="fs-2 fw-bold text-center lh-1">Simula tu plan de pagos</p>

                <p className="text-center texto-simulacion" >
                    Esta simulación es solo una aproximación para el mes de <b>{rates.LabelMonth} {rates.Year}</b> y no refleja los términos finales de una aprobación de tu solicitud con Meddipay. Las condiciones finales del plan de pagos se ajustaran al perfil crediticio de cada cliente.
                </p>

                <div className="">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-4 mt-5 paddin-simu">
                            <p className="text-center fw-bold">Valor de tratamiento</p>
                            <input
                                type="range"
                                className="price"
                                id="price"
                                min={rates.MinAmount}
                                max={rates.MaxAmount}
                                step={100000}
                                value={treatmentAmount}
                                onChange={handleTreatmentAmount1}
                            />
                            <div className="d-flex justify-content-between">
                                <p>{rates.LabelMinAmount}</p>
                                <p>{rates.LabelMaxAmount}</p>
                            </div>

                            <MaskedInput style={{ width: "100%" }} mask={currencyMask} type="text" value={treatmentAmount} onChange={handleTreatmentAmount2} className="d-flex justify-content-center gap-2 text-center bg-mp-shadow-primary p-2 border border-black border-2 rounded-pill mt-4" />

                        </div>

                        <div className="col-md-4 mt-5 paddin-simu">
                            <p className="text-center fw-bold">Plazo</p>
                            <input
                                type="range"
                                className="month"
                                id="month"
                                min={rates.MinTerm}
                                max={rates.MaxTerm}
                                value={treatmentTerm}
                                onChange={(event) => setTreatmentTerm(event.target.value)}
                            />
                            <div className="d-flex justify-content-between">
                                <p>{rates.LabelMinTerm}</p>
                                <p>{rates.LabelMaxTerm}</p>
                            </div>

                            <input style={{ width: "100%" }} type="text" value={treatmentTerm} onChange={handleTerm} className="d-flex justify-content-center gap-2 text-center bg-mp-shadow-primary p-2 border border-black border-2 rounded-pill mt-4" />

                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center my-5">
                    <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={calculate}>Simular</button>
                </div>

                <div className="d-flex justify-content-center">
                    <hr className="mx-5 w-75" style={{ opacity: "1" }} />
                </div>

                <div className="">
                    <div className="row justify-content-center align-items-center">
                        {/* <span className="col-md-3 text-center fs-5 fw-bold">Cuota mensual</span> */}
                        <p className="fs-3 fw-bold text-center lh-1" style={{ margin: "30px 0px 0px" }}>Desde</p>
                        <div
                            id="total"
                            className="mt-3 col-md-3 fw-bold fs-6 d-block justify-content-center gap-2 text-center bg-mp-orange p-3 rounded-pill"
                            style={{ color: "white" }}
                        >
                            ${payment1}/mes
                            <br />
                            {rates.LabelMinAnnualInterestRate} - {rates.LabelMinMonthlyInterestRate}
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">

                        <p className="fs-3 fw-bold text-center lh-1" style={{ margin: "30px 0px 0px" }}>Hasta</p>

                        <div
                            id="total"
                            className="mt-3 col-md-3 fw-bold fs-6 d-block justify-content-center gap-2 text-center bg-mp-orange p-3 rounded-pill"
                            style={{ color: "white" }}
                        >
                            ${payment2}/mes
                            <br />
                            {rates.LabelMaxAnnualInterestRate} - {rates.LabelMaxMonthlyInterestRate}

                        </div>
                    </div>

                    {/* <div className="row justify-content-center align-items-center mt-4">
                        <span className="col-md-3 text-center fs-5 fw-bold text-black">

                            Tasa
                        </span>
                        <button type="button"
                            style={{ border: "none" }}
                            className="position-relative mt-3 col-md-3 fw-bold fs-6 d-block justify-content-center gap-2 text-center bg-mp-green p-3 rounded-pill text-black"
                        >
                            {rates.LabelAnnualBankInterest} - {rates.LabelMonthlyBankInterest}

                         


                            <span style={{ backgroundColor: "#79d853", color: "black" }} ref={target} onClick={() => setShow(!show)} className="position-absolute top-0 start-100 translate-middle badge rounded-pill">
                                ?

                            </span>

                        </button>

                    </div> */}

                    <Overlay target={target.current} show={show} placement="bottom">
                        {(props) => (
                            <Tooltip  {...props} >
                                <div style={{ textAlign: "right", cursor: "pointer", fontWeight: "bold" }} onClick={() => setShow(false)}><i className="bi bi-x-lg"></i></div>
                                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                                    Este calculo toma como referencia  la tasa de Interés interbancaria (TIBC), publicado por la superintendencia financiera y no refleja los términos finales de una aprobación de tu solicitud con Meddipay.
                                </p>

                            </Tooltip>
                        )}
                    </Overlay>

                </div>
            </div>
        </section>

        <section>
            <div className="bg-mp-gray py-5">
                <div className="row w-100" style={{ margin: "0" }}>
                    <div className="col-sm-6 text-center mb-5 d-flex flex-column align-items-center" style={{ padding: "0" }}>
                        <div className="d-flex justify-content-center">
                            <p className="fs-header-secondary text-center">
                                ¿Estas listo?
                            </p>
                        </div>
                        <p className="fs-5"><br /></p>
                        <div className="d-flex justify-content-center flex-wrap gap-5">
                            <button className="mp-big-button bg-mp-primary" style={{ border: "none", marginBottom: "20px" }} onClick={goToChan}>SOLICITA TU CUPO</button>

                        </div>
                    </div>

                    <div className="col-sm-6 text-center mb-5 d-flex flex-column align-items-center">
                        <div className="d-flex justify-content-center">
                            <p className="fs-header-secondary text-center">¿Preguntas?</p>
                        </div>
                        <p className="fs-5">Estamos aquí para resolver tus dudas</p>
                        <div className="d-flex justify-content-center gap-5">
                            <button className="mp-big-button bg-mp-primary" style={{ border: "none", minWidth: "300px" }} onClick={goToFAQClients}>Ver las preguntas frecuentes</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}


export default Clients;