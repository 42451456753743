import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Allies = ({ goToChan, goToAgora }) => {

    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Para aliados";
        window.scrollTo(0, 0)
    }, []);

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }


    const goToFAQAllies = () => {
        navigate("/faqallies");
        window.scrollTo(0, 0)
    }
    return (<>
        <section>
            <div className="container d-flex flex-wrap justify-content-center my-5">
                <div className="col-sm-5">
                    <div>
                        <p className="fs-header">¡Trabajar con</p>
                        <p className="fs-header">Meddipay <span className="text-mp-green">te</span></p>
                        <p className="fs-header">
                            <span className="text-mp-green">da lo más</span>!
                        </p>
                    </div>
                    <div className="lh-32 my-5 text-70" >
                        <p>Meddipay ayuda en la conversión de
                            aquellos clientes que no puedan costear
                            el importe total del tratamiento. Todos
                            ganamos!</p>
                    </div>
                </div>
                <div className="col-sm-7 d-flex justify-content-center">
                    <img
                        className="img-fluid"
                        src="assets/images/img/image-7.svg"
                        alt="person"
                    />
                </div>
            </div>

            <div className="d-flex justify-content-center flex-wrap py-5">
                <Row style={{ width: "80%", textAlign: "center" }} >



                    <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ marginBottom: "50px" }}>

                        <button className="btn-pasos2">1</button>


                        <p className="fs-2 fw-bold">Contáctanos</p>
                        <p>Acordaremos las tarifas, firmaremos un acuerdo de alianza y te daremos de alta en nuestro sistema, así de simple!</p>
                        <span onClick={goToContact} style={{ cursor: "pointer" }}><p className="fs-6 fw-bold">Ir a contacto <i className="bi bi-arrow-right-circle-fill" style={{ color: "#79d853" }}></i></p></span>
                        <img
                            className="img-fluid"
                            src="assets/images/img/hand2.svg"
                            alt="hand2" />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ marginBottom: "50px" }}>
                        <button className="btn-pasos2">2</button>
                        <p className="fs-2 fw-bold">¡Colaboremos!</p>
                        <p>Una vez dado de alta, bríndale a tus clientes la posibilidad de acceder a un cupo con Meddipay y así poder pagar sus tratamientos en cómodas cuotas, para conocer más información visita el siguiente <a href="/clients">Link</a> </p>

                        <img
                            className="img-fluid"
                            src="assets/images/img/hands.svg"
                            alt="hands" />
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ marginBottom: "50px" }}>
                        <button className="btn-pasos2">3</button>
                        <p className="fs-2 fw-bold">¡Tú tienes el control!</p>
                        <p>Te daremos acceso exclusivo a nuestra Web App. Desde ahí podrás enviar invitaciones a los clientes que quieran financiar tus tratamientos.</p>

                        <img
                            className="img-fluid"
                            src="assets/images/img/control.svg"
                            alt="control" />
                    </Col>





                </Row>
            </div>


        </section>



        <section>
            <div className="py-5">
                <div className="w-100">
                    <div
                        className="text-center mb-5 d-flex justify-content-center flex-column align-items-center"
                    >
                        <div className="d-flex justify-content-center">
                            <p className="fs-header-secondary text-center">¿Preguntas?</p>
                        </div>
                        <p className="fs-5">Estamos aquí para resolver tus dudas</p>
                        <div className="d-flex justify-content-center gap-5">
                            <button className="mp-big-button bg-mp-primary" style={{ border: "none", minWidth: "300px" }} onClick={goToFAQAllies}>Ver las preguntas frecuentes</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}



export default Allies;