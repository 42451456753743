import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';


const FAQClients = ({ goToChan, goToAgora }) => {

    const navigate = useNavigate();
    const suggestRef = useRef(null);

    useEffect(() => {
        document.title = "Preguntas frecuentes pacientes";


        const queryParameters = new URLSearchParams(window.location.search)
        const section = queryParameters.get("section")


        if (section === "suggest")
            suggestRef.current.scrollIntoView()

        if (!section)
            window.scrollTo(0, 0)

    }, []);

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    return (<>

        <Container style={{ width: "100%", margin: "0", backgroundColor: "#ff9e25", maxWidth: "100%", paddingTop: "70px", paddingBottom: "70px" }}>
            <Row style={{ textAlign: "center", color: "white" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                    <p className="fs-header">Preguntas frecuentes pacientes</p>
                </Col>
            </Row>
        </Container>

        <div style={{ width: "90%", margin: "50px auto" }}>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>¿Qué es Meddipay?</p>
                <p>Meddipay es una plataforma digital que te ofrece planes de pagos simples y transparentes con los mejores proveedores de salud, bienestar y belleza tales como tratamientos dentales, procedimientos oculares, dermatología, tratamientos de fertilidad, cirugías plástica y veterinaria.</p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué es el cupo y cómo puedo solicitarlo?
                </p>
                <p>
                    El cupo es el total de dinero que te dará Meddipay para que puedas realizarte los tratamientos que desees. Solicita tu cupo desde la comodidad de tu casa, 100% digital, sin papeleos y tendrás respuesta a tu solicitud en minutos. Una vez aprobado tu cupo, podrás usarlo en cualquiera de las clínicas de nuestros aliados.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cuáles  son los requisitos para solicitar mi cupo con Meddipay?
                </p>
                Realiza tu solicitud fácil, rápido y seguro
                <ul>
                    <li>Ser mayor de 18 años.</li>
                    <li>Tener a la mano tu cedula de ciudadanía. </li>
                    <li>Tener un dispositivo con buena conexión a internet y que disponga de cámara.  </li>
                    <li>En algunos casos podremos exigirte requisitos adicionales</li>
                </ul>

            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué tasa de interés se le aplicará a mi plan de pagos?
                </p>
                <p>
                Estas varían según los acuerdos con nuestros aliados, tu perfil como cliente y las tasas establecidas por la superintendencia financiera. Puedes consultar nuestras tasas y tarifas actualizadas mensualmente <a target="_blank" rel="noreferrer" href="/rates">aquí</a>.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>

                    ¿Dónde y cómo puedo realizar los pagos?
                </p>
                <p>
                    Contamos con medios de pago para tarjetas de crédito, débito y transferencia bancaria.  Realiza tus pagos <a target="_blank" rel="noreferrer" href="/payment">aquí</a>
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cómo puedo conocer el estado de mi cuenta?
                </p>
                Puedes conocer esta y mucha más información de tus planes de pago por cualquiera de los siguientes canales:
                <ul>
                    <li>Accediendo a nuestra web app: <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_SKAL}>app.meddipay.com.co</a></li>
                    <li>Escríbenos a WhatsApp: <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573012051542")}>+57 3012051542</a></li>
                    <li>Escríbenos un correo: <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a></li>
                </ul>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cómo puedo aumentar mi cupo?
                </p>
                <p>
                    Si ya tienes un cupo aprobado con Meddipay, pero quieres aumentarlo o solicitar uno nuevo, debes tener un excelente comportamiento con los pagos, cumpliendo siempre con las fechas acordadas y no pasarte de la fecha límite. Si cumples estos requisitos, puedes realizar una nueva solicitud y Meddipay hará un estudio a tu solicitud, teniendo una respuesta al instante.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué sucede si mi cupo es inferior al importe total del tratamiento?
                </p>
                <p>
                    En Meddipay nos ajustamos a la comodidad de tu bolsillo, puedes solicitar la totalidad de tu tratamiento o solo una parte, tú decides que monto financiar. El monto NO financiado debe ser pagado directamente por ti al médico o a la clínica.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué pasa si no pago mi cuota a tiempo?
                </p>
                <p>
                    Si no cuentas con el dinero suficiente te recomendamos que hagas abonos parciales hasta que te pongas al día. Al día siguiente de tu fecha de vencimiento se iniciará el cobro de intereses moratorios por incumplimiento. Si transcurridos 31 días no se realiza el pago, serás reportado a las centrales de riesgo e iniciaremos nuestro proceso de cobro, el cual generará gastos de cobranza a tu cargo.  (Si tienes problemas para cumplir con tus obligaciones escríbenos a WhatsApp: <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573012051542")}>+57 3012051542</a>, llegaremos a algún acuerdo).
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cuántos planes de pago puedo solicitar con Meddipay?
                </p>
                <p>
                    Tu cupo es el monto máximo que podrás gastar, indiferentemente cuantos créditos quieras contratar, tú decides como consumir tu cupo.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Puedo realizar pagos adelantados?
                </p>

                Si, en Meddipay facilitamos la manera de realizar tus pagos.  Puedes pagar por adelantado la cantidad que mejor te convenga y solo tendrás que indicarnos como quieres amortizar este abono, te damos las siguientes tres opciones para tu elección:
                <ul>
                    <li>Abono a capital: el plazo de la finalización de tu plan de pagos disminuirá y se conservará el mismo valor de cuota.</li>
                    <li>Abono a cuota: el valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo.</li>
                    <li>Reserva para futuras cuotas: el valor abonado será asignado a cuotas futuras hasta agotar dicho valor.</li>
                </ul>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cuál es el plazo mínimo y máximo de mi plan de pagos?
                </p>
                <p>
                    Los plazos ofrecidos para los planes de pagos varían dependiendo de los acuerdos con cada aliado y el historial crediticio de cada cliente.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cuáles son nuestros aliados?
                </p>
                <p>
                    Disponemos de un catálogo en nuestra página web donde puedes ver todos nuestros aliados, ingresa al siguiente enlace <a href='/ourallies'>nuestros aliados</a>.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Puedo desistir de mi solicitud una vez firme el contrato o Meddipay haya realizado el desembolso?
                </p>
                <p>
                    De conformidad con el artículo 47 de la Ley 1480 de 2011 en todos los contratos para la venta de bienes
                    y prestación de servicios mediante sistemas de financiación otorgada por el productor, proveedor o
                    ventas que utilizan métodos no tradicionales o a distancia, se entenderá pactado el derecho de retracto
                    por parte del consumidor. Para ejercer dicho derecho, deberás notificar a Meddipay dentro de los 5 días
                    siguientes a la adquisición del servicio de salud o al desembolso del crédito. Posterior a este plazo se
                    entenderá en firme el contrato suscrito y la adquisición del servicio y por tanto, la decisión de un
                    desistimiento recae discrecionalmente sobre Meddipay. Si ejerces el derecho al retracto y no realizas el
                    reintegro total del dinero dentro del plazo otorgado por Meddipay, se entiende en firme y aceptado el crédito en las condiciones iniciales.</p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cómo puedo actualizar mis datos?
                </p>
                <p>
                    Podrás solicitar actualización de tus datos por medio de nuestros canales; WhatsApp al <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573012051542")}>+57 3012051542</a> o por un correo a <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>, un asesor se comunicará contigo para atender a tu solicitud.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué uso le da Meddipay a mis datos?
                </p>
                <p>
                    Con Meddipay tus datos están completamente seguros, unos de los usos serán validar tu identidad y consultar tu historial crediticio con el fin de saber si podemos o no aprobar tus solicitudes de cupo. Ten en cuenta que antes de darle uso a tus datos, solicitaremos tu aprobación, si tienes más dudas, ingresa a los siguientes enlaces donde podrás saber todo sobre la <a href="/privacy">Política de privacidad y tratamiento de datos personales</a> y <a href="/personal">Aviso de privacidad de tratamiento de datos personales</a>.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cómo puedo poner una petición, queja, reclamo o sugerencia?
                </p>
                <p>
                    Puedes enviar una consulta, comentario, sugerencia, petición, queja o reclamo en cualquier momento a través de nuestra <a href="contact">pagina de contacto</a>, una vez enviada, te contactaremos para aclarar y darle respuesta a tu comunicado.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    Han suplantado mi identidad ¿Qué debo hacer?
                </p>

                Si crees que fuiste víctima de suplantación es importante que te comuniques inmediatamente con nosotros para validar la información. Al instante haremos un bloqueo preventivo de cualquier servicio y te sugerimos enviarnos la siguiente documentación:

                <ol>
                    <li>Derecho de petición a través del cual relates lo sucedido;</li>
                    <li>Fotocopia de tu cédula;</li>
                    <li>Denuncia ante la fiscalía general de la Nación.</li>
                </ol>

                Estaremos en constante comunicación contigo hasta darle una pronta solución a tu caso.

            </div>



            <div style={{ width: "100%", marginTop: "30px" }} ref={suggestRef}>
                <p style={{ color: "#ff9e25", fontSize: "20px", fontWeight: "bold" }}>
                    Tengo una clínica de preferencia pero no está aliada con Meddipay ¿Qué puedo hacer?
                </p>

                Si tienes una clínica de preferencia que aún no está asociada con Meddipay, te recomendamos indicarle al médico que se comunique con nosotros. De esta manera, podremos proporcionarle la información necesaria y explorar la posibilidad de establecer una alianza. Esto permitirá que puedas acceder a los servicios de tu clínica favorita por medio de Meddipay.

            </div>

            <div style={{ marginTop: "50px", width: "100%", textAlign: "center" }}>
                <p>
                    ¿No encontraste lo que buscabas?<br />Comunícate con nosotros y te ayudaremos
                </p>
                <br />
                <button onClick={goToContact} className="mp-big-button bg-mp-orange" style={{ border: "none", marginBottom: "20px" }}>Ir a contacto</button>
            </div>


        </div>

    </>)

}

export default FAQClients;