import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import TarsService from '../services/TarsService';
import Swal from 'sweetalert2'

const PaymentResultPSE = ({ goToChan, goToAgora }) => {
    const navigate = useNavigate();
    const { GetPayment } = TarsService();
    const [transactionResult, setTransactionResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const moneyLocale = Intl.NumberFormat('es-CO');
    const [reference, setReference] = useState(null);

    useEffect(() => {


        window.scrollTo(0, 0)


        const queryParameters = new URLSearchParams(window.location.search)
        const transactionId = queryParameters.get("transactionId")

        setLoading(true);





        GetPayment(transactionId).then(res => {
            setLoading(false);

            if (res.status === 200) {
                var data = JSON.parse(res.data.data);
                setTransactionResult(data)
                setReference(data.originationReference)

                if (data.status === "OK") {
                    document.title = "Pago aprobado";
                }
                else if (data.status === "PENDING") {
                    document.title = "Pago pendiente";
                }
                else {
                    document.title = "Pago fallido";
                }

            }
            else {

                navigate("/");

            }
        })

        // "trazabilityCode": "4266299",
        // "status": "NOT_AUTHORIZED",
        // "paymentReference": 39160,
        // "originationReference": 40850368,
        // "transactionValue": 197292,
        // "soliciteDate": "2024-07-18T20:00:10.757"



    }, []);

    useEffect(() => {
        if (!loading) return;

        function beforeUnload(e) {
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [loading]);

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    const goToPayment = () => {
        navigate("/payment?reference=" + reference);
        window.scrollTo(0, 0)
    }

    return (<><Container style={{ marginTop: "20px", marginBottom: "30px" }}>
        <Row style={{ display: transactionResult && transactionResult.status === "OK" ? "flex" : "none" }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img style={{ width: "70px" }}
                    src="assets/images/img/blue_check.svg"
                    alt="payment" />
                <div style={{ margin: "20px auto" }} className="payment-text">
                    <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "#00A9EB" }}>aprobado</span></h1>
                    <p style={{ textAlign: "left", marginTop: "20px" }} >
                        Gracias por tu compromiso, continúa disfrutando de todas las ventajas que Meddipay te ofrece.
                        <br />
                        A continuación encontraras los detalles de la transacción que también te servirá como comprobante de pago.
                    </p>

                    <p style={{ fontWeight: "bold" }}>CUS<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.trazabilityCode}</span></p>
                    <p style={{ fontWeight: "bold" }}>Referencia<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.originationReference}</span></p>
                    <p style={{ fontWeight: "bold" }}>ID Pago<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.paymentReference}</span></p>
                    <p style={{ fontWeight: "bold" }}>Valor<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>${moneyLocale.format(transactionResult?.transactionValue)}</span></p>
                    <p style={{ fontWeight: "bold" }}>Descripción<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.paymentDescription}</span></p>
                    <p style={{ fontWeight: "bold" }}>Entidad financiera<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.financialInstitutionName}</span></p>

                    <p style={{ fontWeight: "bold" }}>Fecha<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.soliciteDate}</span></p>

                </div>

            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img className="img-fluid"
                    src="assets/images/img/payment.svg"
                    alt="payment" />
                <p>  Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                <br />
                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
            </Col>
        </Row>
        <Row style={{ display: transactionResult && (transactionResult.status !== "OK" && transactionResult.status !== "PENDING") ? "flex" : "none" }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img style={{ width: "70px" }}
                    src="assets/images/img/delete.svg"
                    alt="payment" />
                <div style={{ margin: "20px auto" }} className="payment-text">
                    <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "red" }}>{transactionResult?.status === "NOT_AUTHORIZED" ? "rechazado" : "fallido"}</span></h1>
                    <p style={{ textAlign: "left", marginTop: "20px" }} >
                        Ha ocurrido un error a la hora de procesar el pago. A continuacion encontraras mas detalles. </p>

                    <p style={{ fontWeight: "bold" }}>CUS<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.trazabilityCode}</span></p>
                    <p style={{ fontWeight: "bold" }}>Referencia<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.originationReference}</span></p>
                    <p style={{ fontWeight: "bold" }}>ID Pago<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.paymentReference}</span></p>
                    <p style={{ fontWeight: "bold" }}>Valor<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>${moneyLocale.format(transactionResult?.transactionValue)}</span></p>
                    <p style={{ fontWeight: "bold" }}>Descripción<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.paymentDescription}</span></p>
                    <p style={{ fontWeight: "bold" }}>Entidad financiera<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.financialInstitutionName}</span></p>


                    <p style={{ fontWeight: "bold" }}>Fecha<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.soliciteDate}</span></p>


                    <br />
                    <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={goToPayment}>Reintentar</button>
                </div>

            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img className="img-fluid"
                    src="assets/images/img/payment.svg"
                    alt="payment" />
                <p>  Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                <br />
                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
            </Col>
        </Row>

        <Row style={{ display: transactionResult && transactionResult.status === "PENDING" ? "flex" : "none" }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img style={{ width: "70px" }}
                    src="assets/images/img/pending_orange.svg"
                    alt="payment" />
                <div style={{ margin: "20px auto" }} className="payment-text">
                    <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "#ff9e25" }}>pendiente</span></h1>
                    <p style={{ textAlign: "left", marginTop: "20px" }} >
                        Has generado el proceso para realizar el pago pero el pago todavía no se ha realizado. Por favor, sigue las instrucciones que se te han proporcionado para completar el pago.
                        <br />         <br />
                        Por favor verificar si el débito fue realizado en el Banco
                        <br />         <br />
                        No cierres esta pagina, una vez realizado el pago, prueba a actualizar para saber el estado final del pago.
                    </p>
                    <p style={{ fontWeight: "bold" }}>CUS<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.trazabilityCode}</span></p>
                    <p style={{ fontWeight: "bold" }}>Referencia<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.originationReference}</span></p>
                    <p style={{ fontWeight: "bold" }}>ID Pago<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.paymentReference}</span></p>
                    <p style={{ fontWeight: "bold" }}>Valor<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>${moneyLocale.format(transactionResult?.transactionValue)}</span></p>
                    <p style={{ fontWeight: "bold" }}>Descripción<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.paymentDescription}</span></p>

                    <p style={{ fontWeight: "bold" }}>Entidad financiera<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.financialInstitutionName}</span></p>
                    <p style={{ fontWeight: "bold" }}>Fecha<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.soliciteDate}</span></p>


                </div>

            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img className="img-fluid"
                    src="assets/images/img/payment.svg"
                    alt="payment" />
                <p>  Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                <br />
                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
            </Col>
        </Row>
    </Container>

        {loading &&
            (
                <div className="my-loader">
                    <div className="my-loader-container">
                        <img src="assets/images/img/MeddipayLoaderEllipsis.gif" alt="loader"></img>

                    </div>
                </div>
            )}
    </>
    )
}


export default PaymentResultPSE;