import { useEffect } from "react";

const Cookies = ({ goToChan, goToAgora }) => {

    useEffect(() => {
        document.title = "Política de cookies";
        window.scrollTo(0, 0)
    }, []);

    return (<>
        <section>
            <div className="bg-mp-primary text-white">
                <div className="container-fluid p-5 text-justify">
                    <p className="mp-title-size fw-bold">Política de cookies</p>
                </div>
            </div>

            <div className="container">
                <p></p>
                <p>
                    Conforme a la ley 1581 de 2012, la presente pol&iacute;tica de cookies
                    tiene como finalidad informar de manera clara y precisa sobre el
                    tratamiento de las cookies en los sitios web de <b>Meddipay S.A.S</b> (En adelante Meddipay), y sobre la
                    informaci&oacute;n personal recolectada y almacenada.
                </p>
                <p></p>
                <p>
                    Las cookies son un peque&ntilde;o fragmento de texto que se almacenan
                    en el navegador cuando se visita la p&aacute;gina web, las cuales
                    permiten recolectar informaci&oacute;n sobre los h&aacute;bitos de
                    navegaci&oacute;n de los usuarios. Meddipay utiliza cookies propias y
                    de terceros para brindar una mejor experiencia de navegaci&oacute;n.
                </p>
                <p></p>
                <p>
                    Algunas de las cookies se mantienen en el/los dispositivos del
                    usuario, otras se almacenan &uacute;nicamente al momento de
                    navegaci&oacute;n. Estas cookies poseen informaci&oacute;n acerca del
                    tipo de cliente y p&aacute;ginas visitadas frecuentemente.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">ACEPTACI&Oacute;N DE COOKIES</p>
                <p></p>
                <p>
                    Al navegar por cualquiera de las p&aacute;ginas web de Meddipay o
                    haciendo clic en el bot&oacute;n aceptar que aparece en las mismas,
                    Meddipay entiende que usted, como usuario de la web, entiende y acepta
                    el uso de cookies.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">TIPOS DE COOKIES UTILIZADAS</p>
                <p></p>
                <p>
                    Meddipay utiliza en su sitio web y en sus otros dominios las
                    siguientes cookies:
                </p>
                <p></p>
                <p>
                    <b>Cookies de sesi&oacute;n:</b> Meddipay mediante estas cookies se
                    recopila la informaci&oacute;n de acceso y uso durante la
                    navegaci&oacute;n en la web. Estas cookies solo se almacenan durante
                    la navegaci&oacute;n del usuario.
                </p>
                <p></p>
                <p>
                    <b>Cookies funcionales:</b> Mediante estas cookies se almacena la
                    informaci&oacute;n sobre las preferencias del usuario, con el fin de
                    personalizar los servicios y ofrecerlos correctamente.
                </p>
                <p></p>
                <p>
                    <b>Cookies de an&aacute;lisis</b> y/o desempe&ntilde;o: A
                    trav&eacute;s de estas cookies se hace seguimiento y an&aacute;lisis
                    al comportamiento en la navegaci&oacute;n de los usuarios, con el fin
                    de mejorar los servicios ofrecidos por Meddipay.
                </p>
                <p></p>
                <p>
                    <b>Cookies t&eacute;cnicas y/o esenciales:</b> Son las necesarias y
                    esenciales para el uso normal de la web de Meddipay y sus otros
                    dominios. Permiten la correcta navegaci&oacute;n en la web.
                </p>
                <p></p>
                <p>
                    <b>Cookies publicitarias:</b> &nbsp;Mediante estas cookies se recopila
                    la informaci&oacute;n sobre las preferencias de los usuarios de la
                    web, por ende, Meddipay podr&aacute; entregarle al usuario publicidad
                    que considere que puede ser de su inter&eacute;s.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    FINALIDADES DEL USO DE LAS COOKIES
                </p>
                <p></p>
                <p>
                    Meddipay utiliza las cookies en su web y en sus otros dominios con las
                    siguientes finalidades:
                </p>
                <p></p>
                <p>
                    - Brindar al usuario de la web una mejor experiencia de
                    navegaci&oacute;n, contenido e informaci&oacute;n que se ajusten a sus
                    preferencias, a trav&eacute;s de la autenticaci&oacute;n y
                    personalizaci&oacute;n de la navegaci&oacute;n del usuario.
                </p>
                <p>
                    - Brindar seguridad al usuario al momento de la navegaci&oacute;n.
                </p>
                <p>
                    - An&aacute;lisis del rendimiento del sitio web y de los otros
                    dominios de Meddipay.
                </p>
                <p></p>
                <p>
                    Todos los datos recolectados a trav&eacute;s de las cookies,
                    ser&aacute;n tratados conforme a los principios y contenido de la
                    pol&iacute;tica de privacidad y tratamiento de datos personales de
                    Meddipay.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    GESTI&Oacute;N DE LAS COOKIES
                </p>
                <p></p>
                <p>
                    En cualquier momento el usuario podr&aacute; modificar, restringir o
                    deshabilitar la configuraci&oacute;n de las cookies del sitio web de
                    Meddipay y sus dominios desde el navegador.
                </p>
                <p></p>
                <p>
                    Todos los navegadores brindan la posibilidad de modificar, restringir
                    o deshabilitar las cookies para cada una de las p&aacute;ginas web.
                </p>
                <p>
                    Si se restringe o modifica el uso de las cookies, se ver&aacute;
                    afectada la experiencia de navegaci&oacute;n en la web y algunas de
                    sus funcionalidades.
                </p>
                <p></p>
                <p className="text-mp-primary fs-3 fw-bold">
                    ACTUALIZACIONES Y/O CAMBIOS EN LA POL&Iacute;TICA DE COOKIES
                </p>
                <p></p>
                <p>
                    Meddipay se conserva el derecho de modificar y/o actualizar la
                    presente pol&iacute;tica de cookies en cualquier momento, atendiendo a
                    los requerimientos legales y funcionales, respetando los derechos de
                    los usuarios.
                </p>
                <p></p>
                <p>
                    Se les recomienda a los usuarios revisar peri&oacute;dicamente la
                    presente pol&iacute;tica de cookies.
                </p>
                <p></p>
            </div>
        </section>
    </>)
}


export default Cookies;