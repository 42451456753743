import { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";

const Home = ({ goToChan, goToAgora }) => {

    const [openedAc, setOpenedAc] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        //document.title = "Inicio";
        window.scrollTo(0, 0)
    }, []);

    const goToAboutUs = () => {
        localStorage.setItem("scrollToQuestions", "1")
        navigate("/aboutus");
    }

    // const goToWompi = () => {
    //     window.open("https://checkout.wompi.co/l/VV3GcV", "_blank", "noreferrer")

    // }

    const  goToPayment= () => {
        navigate("/payment");
    }

    return (<>
        <Container style={{ minHeight: "85vh" }}>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className="fs-header">¡La forma más simple de<span className="text-mp-primary"> financiar</span> tu salud!</span>

                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className="text-home" style={{ marginTop: "20px" }}>
                                Meddipay es una plataforma digital que te conecta con los mejores
                                proveedores de salud, bienestar y belleza y te ayuda con planes de
                                pago accesibles y transparentes.
                            </p>

                            <Row style={{ marginTop: "50px", textAlign: "center" }}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <button className="mp-big-button bg-mp-primary" style={{ border: "none", marginBottom: "20px" }} onClick={goToChan}>Solicita tu Cupo</button>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <button className="mp-big-button bg-mp-orange" style={{ border: "none", marginBottom: "20px" }} onClick={goToPayment}>Paga tu Cuota</button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>



                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <img className="img-fluid"
                        src="assets/images/img/image-1.svg"
                        alt="person" />
                </Col>



            </Row>
        </Container>
        <section>
            <div className="bg-mp-gray py-5">
                <div className="d-flex justify-content-center py-5">
                    <p className="fs-header-secondary text-center">Lo que nos diferencia</p>
                </div>

                <div className="d-flex justify-content-center container">
                    <div className="row w-100">
                        <div className="col-sm-4 text-center mb-5 d-flex justify-content-center flex-column align-items-center">
                            <img
                                className="img-fluid mb-5"
                                src="assets/images/img/simple.svg"
                                alt=""
                            />
                            <p className="fs-3">Simple</p>
                        </div>
                        <div
                            className="col-sm-4 text-center mb-5 d-flex justify-content-center flex-column align-items-center">
                            <img
                                className="img-fluid mb-5"
                                src="assets/images/img/transparente.svg"
                                alt="" />
                            <p className="fs-3">Transparente</p>
                        </div>
                        <div
                            className="col-sm-4 text-center mb-5 d-flex justify-content-center flex-column align-items-center">
                            <img
                                className="img-fluid mb-5"
                                src="assets/images/img/tecnologica.svg"
                                alt=""
                            />
                            <p className="fs-3">Tecnológica</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="row w-100 my-5">
                <div className="d-flex flex-column align-content-center py-5">
                    <p className="fs-header-secondary text-center lh-1">Especialidades de</p>
                    <p className="fs-header-secondary text-center lh-1">nuestros Aliados</p>
                </div>
                <div className="col-sm-6">
                    <div className="accordion-container">

                        <div className="container-special">
                            <button onClick={() => { openedAc === 1 ? setOpenedAc(0) : setOpenedAc(1) }}
                                className={`btn-special accordion text-white rounded-5 ${openedAc === 1 ? "open active" : ""}`}>
                                <span className="fw-bold">Tratamientos Dentales</span>
                            </button>
                            <div className="panel" style={{ maxHeight: openedAc === 1 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    Si tienes alguna patología dental o deseas mejorar la estética de tu sonrisa, te facilitaremos el acceso a tratamientos con nuestros aliados altamente cualificados, brindando un procedimiento adecuado y ofreciendo resultados óptimos para tu salud dental.
                                </p>
                            </div>
                        </div>


                        <div className="container-special">
                            <button onClick={() => { openedAc === 2 ? setOpenedAc(0) : setOpenedAc(2) }}
                                className={`btn-special accordion rounded-5  ${openedAc === 2 ? "open active" : ""}`}
                            >
                                <span className="fw-bold">Procedimientos Oculares</span>
                            </button>
                            <div className="panel text-black" style={{ maxHeight: openedAc === 2 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    Tus ojos se merecen el mejor trato, nosotros te apoyamos a tomar el mejor plan de pagos para que puedas disponer de servicios profesionales de optometría y tratamientos de prevención de las patologías relacionadas con los ojos, la visión, la vía óptica y los anexos.

                                </p>
                            </div>
                        </div>


                        <div className="container-special">
                            <button onClick={() => { openedAc === 3 ? setOpenedAc(0) : setOpenedAc(3) }}
                                className={`btn-special accordion text-white rounded-5 ${openedAc === 3 ? "open active" : ""}`}

                            >
                                <span className="fw-bold">Tratamientos Dermatológicos</span>
                            </button>
                            <div className="panel" style={{ maxHeight: openedAc === 3 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    Con nuestros servicios nos ajustamos a tus necesidades y te brindamos la oportunidad de elegir entre nuestros comercios aliados. Es el momento de darle importancia a la salud de tu piel con procedimientos de dermatología y medicina estética.
                                </p>
                            </div>
                        </div>


                        <div className="container-special">
                            <button onClick={() => { openedAc === 4 ? setOpenedAc(0) : setOpenedAc(4) }}
                                className={`btn-special accordion text-white rounded-5 ${openedAc === 4 ? "open active" : ""}`}
                            >
                                <span className="fw-bold">Tratamientos de Fertilidad</span>
                            </button>
                            <div className="panel text-black" style={{ maxHeight: openedAc === 4 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    Con Meddipay tienes un respaldo que te brindará la oportunidad de financiar hasta el 100% de tu tratamiento de fertilidad. Por medio de nuestros aliados expertos, te ayudaremos a elegir tu mejor opción, siendo tu salud nuestra principal prioridad.
                                </p>
                            </div>
                        </div>


                        <div className="container-special">
                            <button onClick={() => { openedAc === 5 ? setOpenedAc(0) : setOpenedAc(5) }}
                                className={`btn-special accordion text-white rounded-5 ${openedAc === 5 ? "open active" : ""}`}
                            >
                                <span className="fw-bold">Procedimientos Ortopédicos</span>
                            </button>
                            <div className="panel" style={{ maxHeight: openedAc === 5 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    Somos tu mejor elección para acceder a tus procedimientos ortopédicos con planes que se ajustan a tu presupuesto. Contamos con una variedad de aliados que tienen altas experiencias en procedimientos de esta especialidad.
                                </p>
                            </div>
                        </div>


                        <div className="container-special">
                            <button onClick={() => { openedAc === 6 ? setOpenedAc(0) : setOpenedAc(6) }}
                                className={`btn-special accordion text-black rounded-5 ${openedAc === 6 ? "open active" : ""}`}
                            >
                                <span className="fw-bold">Cirugía Plastica</span>
                            </button>
                            <div className="panel text-black" style={{ maxHeight: openedAc === 6 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    En Meddipay nos sentimos honrados de poder ofrecerte alternativas y ayudarte por medio de pagos accesibles. Trabajamos con especialistas que harán todo por cumplir tus objetivos brindándote excelentes resultados, prevaleciendo el bienestar y seguridad del paciente.
                                </p>
                            </div>
                        </div>


                        <div className="container-special">
                            <button onClick={() => { openedAc === 7 ? setOpenedAc(0) : setOpenedAc(7) }}
                                className={`btn-special accordion text-white rounded-5 ${openedAc === 7 ? "open active" : ""}`}

                            >
                                <span className="fw-bold">Veterinaria </span>
                            </button>
                            <div className="panel" style={{ maxHeight: openedAc === 7 ? "800px" : null, maxWidth: "750px", fontWeight: "500" }}>
                                <p className="p-4">
                                    En Meddipay te brindamos planes de pagos según tu necesidad, tenemos diferentes tipos de aliados que te ofrecerán todos los servicios y cuidados para mascotas, como consulta general y especializada, medicina biorreguladora, peluquería, vacunación, hospitalización, cirugía, odontología, laboratorio clínico, rayos X, ecografía, urgencias y hasta compras para tus mascotas.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-sm-6 d-flex justify-content-center align-items-center" style={{ zIndex: "-999" }}>
                    <img
                        className="img-fluid d-none d-sm-block"
                        src="assets/images/img/image-2.svg"
                        alt="person"
                    />
                </div>
            </div>
        </section>

        <section>
            <div className="bg-mp-gray py-5">
                <div className="row w-100" style={{ margin: "0" }}>
                    <div className="col-sm-6 text-center mb-5 d-flex flex-column align-items-center" style={{ padding: "0" }}>
                        <div className="d-flex justify-content-center">
                            <p className="fs-header-secondary text-center">
                                ¿Listo para<br />empezar?
                            </p>
                        </div>

                        <div className="d-flex justify-content-center flex-wrap gap-5">
                            <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToChan} >Soy paciente</button>
                            <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={goToAgora}>Soy aliado</button>
                        </div>
                    </div>

                    <div className="col-sm-6 text-center mb-5 d-flex justify-content-center flex-column align-items-center">
                        <div className="d-flex justify-content-center">
                            <p className="fs-header-secondary text-center">¿Preguntas?</p>
                        </div>
                        <p className="fs-5">Estamos aquí para resolver tus dudas</p>
                        <div className="d-flex justify-content-center gap-5">
                            <button className="mp-big-button bg-mp-primary" style={{ border: "none", minWidth: "300px" }} onClick={goToAboutUs}>Ver las preguntas frecuentes</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>);
}


export default Home;