import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


const FAQAllies = ({ goToChan, goToAgora }) => {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Preguntas frecuentes Aliados";
        window.scrollTo(0, 0)
    }, []);

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    return (<>

        <Container style={{ width: "100%", margin: "0", backgroundColor: "#79D853", maxWidth: "100%", paddingTop: "70px", paddingBottom: "70px" }}>
            <Row style={{ textAlign: "center", color: "white" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                    <p className="fs-header">Preguntas frecuentes Aliados</p>
                </Col>
            </Row>
        </Container>

        <div style={{ width: "90%", margin: "50px auto" }}>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>¿Qué es Meddipay?</p>
                <p>
                    Meddipay te apoya ofreciéndole a tus pacientes alternativas de pago y así te ayuda a incrementar tus ventas, recibiendo tus pagos de forma segura y puntual.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    Me quiero asociar con Meddipay ¿Qué debo hacer?
                </p>
                <p>
                    Para ser un aliado con Meddipay ponte en contacto a través del siguiente enlace <a href="contact">ir a contacto</a>, indícanos nombre comercial, el sector al que pertenece tu negocio y descríbenos brevemente el servicio que deseas ofrecer o dudas al respecto.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cómo funciona Meddipay?

                </p>
                <p>
                    Como aliado, te daremos acceso exclusivo a nuestra Web App, desde la cual podrás enviar las invitaciones de los pacientes interesados en solicitar financiación de tus tratamientos con su respectivo presupuesto, una vez el paciente tenga la financiación aprobada y firmada, se procederá a hacer efectivo el desembolso a la clínica y podrás comenzar con el tratamiento del paciente.

                </p>

            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué son y cómo funcionan las invitaciones?
                </p>
                <p>
                    Para que el paciente pueda disponer de su cupo, necesita previamente que tú le envíes una invitación. En la invitación deberás indicar: la cédula del paciente, datos de contacto (opcionales), el importe del tratamiento y el importe a financiar.

                    De esta forma, el paciente al ingresar a la plataforma de Meddipay, gestionará su cupo, podrá acceder a dicha invitación y continuar con el proceso de financiación.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>

                    ¿Cuándo será desembolsado el monto financiado de mi paciente?
                </p>
                <p>
                    Una vez el cliente haya firmado contrato con Meddipay el desembolso podrá tardar entre cinco y diez días hábiles.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>

                    ¿Qué tasa de interés maneja Meddipay?
                </p>
                <p>
                    Antes de firmar el contrato, el paciente conocerá de primera mano que tasa de interés tendrá su plan de pagos; estas varían según los acuerdos con nuestros aliados, siendo desde el 0% hasta la tasa de usura vigente para el mes en curso.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Cuál es el monto mínimo y máximo de financiación?
                </p>
                <p>
                    El máximo de monto dependerá del perfil crediticio del cliente y los acuerdos que se tengan con cada aliado.
                </p>
            </div>



            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>

                    ¿Qué pasa si el cupo del paciente no cubre la totalidad del tratamiento?
                </p>
                <p>
                    Si el cupo del paciente es menor al importe del tratamiento, se sugiere llegar a un acuerdo entre las dos partes implicadas, siendo la practica más común, que el paciente adelante la diferencia entre el importe del tratamiento y el cupo. El paciente no podrá financiar importes superiores a su cupo.

                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué pasa si mi paciente no paga?
                </p>
                <p>
                    Nosotros nos encargaremos completamente de la gestión de cobro, sin embargo, si el paciente incumple con los pagos a Meddipay, nos veremos obligados a reportar al paciente a las centrales de riesgo. Tu como aliado no tienes ninguna responsabilidad sobre la financiación, pero si es recomendable que le recuerdes y facilites a los pacientes los medios de pago para que el cliente no incurra en mora. Recuerda, cuantos más clientes con buen manejo de pago tengas, podrás optar a mejores condiciones con Meddipay.
                </p>
            </div>



            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Puedo ver el estado de las ventas de mi clínica?
                </p>
                <p>
                    En el portal de aliados de Meddipay, tendrás acceso a gráficas, reportes y monitoreo a los tratamientos que tus pacientes hayan financiado.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Puedo anular un tratamiento ya financiado?
                </p>
                <p>
                    De conformidad con el artículo 47 de la Ley 1480 de 2011 en todos los contratos para la venta de bienes y
                    prestación de servicios mediante sistemas de financiación otorgada por el productor o proveedor o ventas que utilizan métodos no tradicionales o a distancia, se entenderá pactado el derecho de retracto por parte del consumidor. Para ejercer dicho derecho, el cliente deberá notificar a Meddipay dentro de los 5 días siguientes a la adquisición del servicio de salud o al desembolso del crédito. Posterior a este plazo, se entenderá en firme el contrato suscrito y la adquisición del servicio y por tanto, la decisión de un desistimiento recae discrecionalmente sobre Meddipay. Podrás cancelar la financiación del tratamiento a través del portal de aliados de Meddipay. Esto implicará que deberás realizar una nota debito del servicio y te descontaremos estas cancelaciones en futuros desembolsos de tratamientos financiados. Si necesitas realizar una cancelación mayor a 5 días, ponte en contacto con un asesor de Meddipay.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Qué responsabilidad tengo como aliado con Meddipay?
                </p>
                <p>
                    Tu principal responsabilidad como aliado de Meddipay es evitar transacciones fraudulentas tales como la suplantación de identidad.

                    Aunque no sea una responsabilidad directa, agradecemos toda la ayuda que puedas brindarle al paciente para su registro en la plataforma de Meddipay y proporcionarle información de los canales dispuestos para el pago de sus cuotas con Meddipay.
                </p>
            </div>

            <div style={{ width: "100%", marginTop: "30px" }}>
                <p style={{ color: "#79D853", fontSize: "20px", fontWeight: "bold" }}>
                    ¿Dispongo de material publicitario?
                </p>
                <p>
                    Comunícate con tu asesor designado para que te proporcione el material necesario para mayor captación de clientes o escríbenos a los siguientes medios, WhatsApp al <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573012051542")}>+57 3012051542</a> o por un correo a <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>.
                </p>
            </div>


            <div style={{ marginTop: "50px", width: "100%", textAlign: "center" }}>
                <p>
                    ¿No encontraste lo que buscabas?<br />Comunícate con nosotros y te ayudaremos
                </p>
                <br />
                <button onClick={goToContact} className="mp-big-button bg-mp-green" style={{ border: "none", marginBottom: "20px" }}>Ir a contacto</button>
            </div>


        </div>

    </>)

}

export default FAQAllies;