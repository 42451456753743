import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TarsService from "../services/TarsService"
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const OurAllies = ({ goToChan, goToAgora }) => {

    const navigate = useNavigate();
    const { GetAlliesCache } = TarsService();
    const [City, setCity] = useState([]);
    const [specialty, setSpecialty] = useState([]);

    const [selectedCity, setSelectedCity] = useState("Ciudad");
    const [selectedSpecialty, setSelectedSpecialty] = useState("Especialidad");
    const [selectedClinic, setSelectedClinic] = useState(null);


    const [clinics, setClinics] = useState(null);
    const [originalClinics, setOriginalClinics] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false);



    const filterClinics = (value, type) => {


        let clinicsTemp = []

        if (type === 1) {
            setSelectedCity(value)
            originalClinics.forEach(c => {
                if (evaluateBranchCities(c.Branches, value) && evaluateClinicSectors(c.Sectors, selectedSpecialty))
                    clinicsTemp.push(c)
            });

            setClinics(clinicsTemp)

        }
        if (type === 2) {

            setSelectedSpecialty(value)

            originalClinics.forEach(c => {
                if (evaluateBranchCities(c.Branches, selectedCity) && evaluateClinicSectors(c.Sectors, value))
                    clinicsTemp.push(c)
            });

            setClinics(clinicsTemp)


        }
    }

    const goToFAQClients = () => {
        navigate({ pathname: '/faqclients', search: '?section=suggest', });
        window.scrollTo(0, 0)
    }

    const evaluateBranchCities = (Branches, value) => {


        let res = false;
        if (Branches)
            for (let index = 0; index < Branches.length; index++) {
                const b = Branches[index];
                if ((b.City === value || value === "Ciudad")) {
                    res = true;
                    break;
                }
            }
        else if (!Branches && value === "Especialidad")
            res = true;

        return res;

    }


    const evaluateClinicSectors = (Sectors, value) => {


        let res = false;
        if (Sectors)
            for (let index = 0; index < Sectors.length; index++) {
                const s = Sectors[index];
                if (s.Sector === value || value === "Especialidad") {
                    res = true;
                    break;
                }
            }
        else if (!Sectors && value === "Especialidad")
            res = true;

        return res;

    }

    useEffect(() => {
        document.title = "Nuestros aliados";
        window.scrollTo(0, 0)
        setLoading(true)
        let cities = []
        let sectors = []

        setCity([])
        setSpecialty([])

        GetAlliesCache().then(res => {
            setLoading(false)
            let data = JSON.parse(res.data.data);

         
            setClinics(data.cache)
            setOriginalClinics(data.cache)


            data.cache.forEach(clinica => {

                if (clinica.Branches)
                    clinica.Branches.forEach(branch => {
                        if (!cities.includes(branch.City)) {
                            cities.push(branch.City)
                            setCity(City => [...City, branch.City])
                        }

                    });
                if (clinica.Sectors)
                    clinica.Sectors.forEach(s => {
                        if (!sectors.includes(s.Sector)) {
                            sectors.push(s.Sector)
                            setSpecialty(specialty => [...specialty, s.Sector])
                        }

                    });

            });
        })
    }, []);



    return (<>

        <Container>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className="fs-header">Nuestros<br /> aliados</span>

                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className="text-home" style={{ marginTop: "30px" }}>
                                Elige tu ciudad y la especialidad
                                que buscas <br />en el desplegable y automáticamente se<br /> mostrarán nuestras clínicas disponibles.
                            </p>


                        </Col>

                    </Row>



                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <img className="img-fluid"
                        src="assets/images/img/ourallies.svg"
                        alt="person" />
                </Col>



            </Row>

            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }} >
                    <br />
                    <p>¿No encuentras tu clínica de preferencia?</p>
                    <button className="mp-big-button bg-mp-primary" onClick={goToFAQClients} style={{ border: "none", width: "350px" }}>Recomienda un Aliado</button>
                </Col>
            </Row>

            <Row style={{ marginTop: "50px" }}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="column-right-oa">
                    <select className="form-select select-oa-orange" style={{ border: "none", marginBottom: "20px" }} onChange={(event) => filterClinics(event.target.value, 1)}>

                        <option defaultValue={null}>Ciudad</option>
                        {City.sort().map(c => {
                            return <option key={c} defaultValue={c}>{c}</option>
                        })}
                    </select>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="column-left-oa" >
                    <select className="form-select select-oa-green" style={{ border: "none", marginBottom: "20px" }} onChange={(event) => filterClinics(event.target.value, 2)}>

                        <option defaultValue={null}>Especialidad</option>
                        {specialty.sort().map(c => {
                            return <option key={c} defaultValue={c}>{c}</option>
                        })}
                    </select>
                </Col>
            </Row>
        </Container>

        {loading ? (<Container>
            <Row style={{ textAlign: "center" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Spinner animation="border" role="status" style={{ color: "#00a9eb" }} >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></Col>
            </Row>
        </Container>


        ) : clinics && clinics.length > 0 ? (

            <Container style={{}}>
                <Row>

                    {clinics.map(c => {
                        return (<Col xs={12} sm={12} md={6} lg={6} xl={4} key={c.Id} style={{ marginBottom: "20px" }}>
                            <div style={{ backgroundColor: "#F0F0F0", borderRadius: "15px", padding: "20px", marginBottom: "20px", height: "100%" }}>
                                <table className="table-oa">
                                    <tbody>
                                        <tr>
                                            <td style={{ color: "#00A9EB", fontWeight: "bold", width: "50%", fontSize: "16px" }}>Nombre</td>
                                            <td style={{ color: "#00A9EB", fontWeight: "bold", fontSize: "16px" }}>Clínicas</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: "top" }}>
                                                <p style={{ fontSize: "16px" }}>{c.CommercialName}</p>




                                            </td>
                                            <td style={{ verticalAlign: "top" }} rowSpan={5}>
                                                {c.Branches && c.Branches.slice(0, 3).map(b => {
                                                    return (
                                                        <Container key={b.Id} style={{ padding: "0", cursor: "pointer" }} >
                                                            <Row>
                                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                                    <p style={{ fontSize: "16px" }}>{b.DisplayName}<br /><span style={{ fontSize: "14px" }}>{b.AddressLabel}</span></p>
                                                                </Col>
                                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "right", padding: "1rem 0" }}>
                                                                    <i style={{ color: "#00A9EB" }} className="bi bi-arrow-right-circle-fill"></i>
                                                                </Col>
                                                            </Row>

                                                        </Container>

                                                    )
                                                })}
                                                {c.Branches && c.Branches.length > 3 && <p onClick={() => { setSelectedClinic(c); setShow(true) }} style={{ fontWeight: "bold", cursor: "pointer", fontSize: "16px" }}>Ver todas <i style={{ color: "#00A9EB" }} className="bi bi-plus-circle-fill"></i></p>}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td style={{ color: "#00A9EB", fontWeight: "bold", fontSize: "16px" }}>Especialidad</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: "top" }}>{c.Sectors && c.Sectors.map(s => { return (<p style={{ fontSize: "16px" }} key={c.Id + "_" + s.Sector}>{s.Sector}</p>) })}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: "#00A9EB", fontWeight: "bold", fontSize: "16px" }}>Web</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: "top" }}>   <p onClick={() => { window.open(c.Web, "_blank", "noreferrer") }} style={{ cursor: "pointer", fontSize: "16px" }}>Portal aliado <i style={{ color: "#00A9EB" }} className="bi bi-arrow-right-circle-fill"></i></p></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>



                        </Col>)
                    })}

                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <button className="mp-big-button bg-mp-primary" style={{ border: "none", marginBottom: "20px" }} onClick={goToChan}>SOLICITA TU CUPO</button>
                    </Col>
                </Row>
            </Container>


        ) : (<>
            <Container>
                <Row style={{ textAlign: "center" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>No hay aliados que cumplan los filtros</Col>
                </Row>
            </Container>
        </>)}

        <Modal show={show} onHide={handleClose} dialogClassName="modal-aut">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", color: "#00A9EB", fontWeight: "bold", width: "100%", fontSize: "20px" }}>Clínicas</div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>

                        {selectedClinic && selectedClinic.Branches.map(b => {

                            return (

                                <Col xs={12} sm={12} md={4} lg={4} xl={4} key={b.Id} style={{ marginBottom: "20px" }} >
                                    <div style={{ backgroundColor: "#F0F0F0", borderRadius: "15px", padding: "20px", marginBottom: "20px", height: "100%" }}>
                                        <table className="table-oa">
                                            <tbody>
                                                <tr>
                                                    <td style={{ color: "#00A9EB", fontWeight: "bold", width: "50%", fontSize: "16px" }}>Nombre</td>

                                                </tr>
                                                <tr>
                                                    <td><p>{b.DisplayName}</p></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ color: "#00A9EB", fontWeight: "bold", width: "50%", fontSize: "16px" }}>Dirección</td>

                                                </tr>
                                                <tr>
                                                    <Container style={{ padding: "0", cursor: "pointer" }} onClick={() => { window.open('tel:' + b.Phone) }}>
                                                        <Row>
                                                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                                <p style={{ cursor: "pointer", fontSize: "16px" }}>{b.AddressLabel}</p>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "right" }}>
                                                                <i style={{ color: "#00A9EB" }} className="bi bi-arrow-right-circle-fill"></i>
                                                            </Col>
                                                        </Row>

                                                    </Container>
                                                </tr>

                                                <tr>
                                                    <td style={{ color: "#00A9EB", fontWeight: "bold", width: "50%", fontSize: "16px" }}>Teléfono</td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Container style={{ padding: "0", cursor: "pointer" }} onClick={() => { window.open('tel:' + b.Phone) }}>
                                                            <Row>
                                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                                    <p style={{ cursor: "pointer", fontSize: "16px" }}>{b.Phone}</p>
                                                                </Col>
                                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "right" }}>
                                                                    <i style={{ color: "#00A9EB" }} className="bi bi-arrow-right-circle-fill"></i>
                                                                </Col>
                                                            </Row>

                                                        </Container>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ color: "#00A9EB", fontWeight: "bold", width: "50%", fontSize: "16px" }}>Web</td>

                                                </tr>
                                                <tr>
                                                    <td>

                                                        <Container style={{ padding: "0", cursor: "pointer" }} onClick={() => { window.open(b.WebSite, "_blank", "noreferrer") }}>
                                                            <Row>
                                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                                    <p style={{ cursor: "pointer", fontSize: "16px" }}>Web clínica</p>
                                                                </Col>
                                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "right" }}>
                                                                    <i style={{ color: "#00A9EB" }} className="bi bi-arrow-right-circle-fill"></i>
                                                                </Col>
                                                            </Row>

                                                        </Container>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>



                                </Col>
                            )
                        })}

                    </Row>
                </Container>
            </Modal.Body>


        </Modal>

    </>)

}

export default OurAllies;