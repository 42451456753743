import { useEffect } from "react";

const Contact = ({ goToChan, goToAgora }) => {

    useEffect(() => {
        document.title = "Contacto";
        window.scrollTo(0, 0)
    }, []);

    const openWA = () => {

        window.open("https://wa.me/" + encodeURI("+573011633330"))
    }

    const openTel = () => {

        window.open('tel:+576015803318')
    }

    const openEmail = () => {

        window.open("mailto:hola@meddipay.com")
    }




    return (<>

        <section className="py-5">
            <div className="container d-flex flex-wrap justify-content-center my-5">
                <div>
                    <p className="fs-header text-center">¿Quieres saber más?</p>
                    <p className="fs-header text-center">
                        <span className="text-mp-green">¡Hablemos!</span>
                    </p>
                </div>
                <p>Estos medios de contacto también son canales que podrás utilizar para realizar PQRS (peticiones, quejas, reclamos o sugerencias)</p>
            </div>

            
            <div className="d-flex justify-content-center flex-wrap py-3">

                <div style={{ width: "350px", cursor: "pointer", height: "100px" }} className="mp-big-button bg-mp-green d-flex align-items-center justify-content-center gap-2" onClick={openWA}>
                    <div>
                        <img className="img-fluid w-75" src="assets/images/icons/whatsapp.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="lh-0 m-0">Escríbenos a WhatsApp</p>
                        <p className="lh-0 m-0">+57 301 163 3330</p>
                    </div>
                </div>
            </div>


            <div className="d-flex justify-content-center flex-wrap py-3">
                <div style={{ width: "350px", cursor: "pointer", height: "100px" }} className="mp-big-button bg-mp-primary d-flex align-items-center justify-content-center gap-2" onClick={openEmail}>
                    <div>
                        <img className="img-fluid w-75" src="assets/images/icons/mail.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="lh-0 m-0">Escríbenos un Correo a:</p>
                        <p className="lh-0 m-0">hola@meddipay.com</p>
                    </div>
                </div>
            </div>


            <div className="d-flex justify-content-center flex-wrap py-3">
                <div style={{ width: "350px", cursor: "pointer", height: "100px" }} className="mp-big-button bg-mp-orange d-flex align-items-center justify-content-center gap-2" onClick={openTel}>
                    <div>
                        <img className="img-fluid w-75" src="assets/images/icons/phone.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="lh-0 m-0">Llámanos al:</p>
                        <p className="lh-0 m-0">+57 601 580 3318</p>
                    </div>
                </div>
            </div>
        </section>

    </>)

}

export default Contact;