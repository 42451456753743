import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const AboutUs = ({ goToChan, goToAgora }) => {

    const [showAlliesFAQ, setShowAlliesFAQ] = useState(false);
    const [showClientsFAQ, setShowClientsFAQ] = useState(true);
    const [openedAc, setOpenedAc] = useState(0);


    const navigate = useNavigate();

    useEffect(() => {

        document.title = "Quiénes somos";
        let scrollToQuestions = localStorage.getItem("scrollToQuestions")
        if (scrollToQuestions === "1") {
            setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: 'smooth' });
            }, 400);
            localStorage.setItem("scrollToQuestions", "0")
        }
        else {
            //window.scrollTo(0, 0)
        }

    }, []);

    const ref = useRef(null);

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    const goToFAQClients = () => {
        navigate("/faqclients");
        window.scrollTo(0, 0)
    }

    const goToFAQAllies = () => {
        navigate("/faqallies");
        window.scrollTo(0, 0)
    }

    return (<>
        <section>
            <div className="container d-flex flex-wrap justify-content-center my-5" style={{ marginBottom: "0px" }}>
                <div className="col-sm-5">
                    <div id="header-aboutus1">
                        <p className="fs-header lh-1">Sentirse
                            <br />
                            <span className="text-mp-primary"> bien </span>para<br />

                            estar <span className="text-mp-primary">bien</span>
                        </p>
                    </div>
                    <div id="header-aboutus2">
                        <p className="fs-header lh-1">Sentirse

                            <span className="text-mp-primary"> bien </span><br />para

                            estar <span className="text-mp-primary">bien</span>
                        </p>
                    </div>
                </div>
                <div className="col-sm-7 d-flex justify-content-center">
                    <img
                        className="img-fluid"
                        src="assets/images/img/image-3.svg"
                        alt="person"
                        style={{ width: "290px" }}
                    />
                </div>
            </div>
            <div className="container d-flex flex-wrap py-5">
                <div className="col-sm-5">
                    <div className="fs-2 lh-1">
                        <p className="text-mp-primary fw-bold">Nuestra visi&oacute;n</p>
                        <p>Que estar bien, sentirse</p>
                        <p>bien y verse bien sean</p>
                        <p>una realidad.</p>
                    </div>
                </div>
                <div className="col-sm-7 d-flex">
                    <div>
                        <p>Nuestro objetivo es que m&aacute;s y m&aacute;s
                            personas puedan tener acceso a los
                            mejores profesionales m&eacute;dicos, centros de
                            salud, bienestar y belleza, pero no a
                            cualquier precio, sino a un costo accesible y,
                            sobre todo, a trav&eacute;s de un financiamiento
                            simple, r&aacute;pido y transparente.</p>
                    </div>
                </div>
            </div>
        </section>


        <section>
            <div className="bg-mp-primary py-5">
                <div className="container py-5">
                    <p className="fs-bold fs-header-generic text-center text-white">
                        Súmate a Meddipay y acompáñanos en la transformación del acceso a la
                        salud y el bienestar en América Latina.
                    </p>
                </div>
            </div>
        </section>

        <section>
            <div className="container d-flex justify-content-center flex-wrap py-5">
                <div className="col-sm-5 py-4 container">
                    <div className="fs-2 lh-1">
                        <p className="text-mp-primary fw-bold">Nuestro Equipo</p>
                        <p>Un grupo joven con<br /> mucha experiencia en<br /> fintech</p>
                    </div>
                    <div>
                        <p>Meddipay fue fundada en el 2022 por un equipo con mucho recorrido a sus espaldas, cuentan con más de 10 años de experiencia en el sector financiero y tecnológico. Es un equipo joven y con mucha ambición e ideas disruptivas para acabar con los métodos tradicionales que limitan la inclusión financiera y el acceso a la salud. Un equipo multicultural con el compromiso de aportar al desarrollo socioeconómico de la región.</p>
                    </div>
                </div>
                <div className="col-sm-7 d-flex justify-content-center">
                    <img
                        className="img-fluid"
                        src="assets/images/img/image-5.svg"
                        alt="person"
                    />
                </div>
            </div>
        </section>

        <section id="questions" ref={ref}>

            <div className="d-flex flex-column align-content-center py-3">
                <p className="fs-header-secondary text-center lh-2" style={{ lineHeight: "45px" }}>
                    Preguntas<br className="salto" />  frecuentes
                </p>
            </div>

            <div className="container d-flex justify-content-center gap-4 mb-5">
                <button
                    className={showClientsFAQ ? "btn-solid-orange" : "btn-outline-orange"}

                    id="btnContenido1"

                    onClick={() => { setShowAlliesFAQ(false); setShowClientsFAQ(true) }}>Para pacientes</button>
                <button
                    className={showAlliesFAQ ? "btn-solid-green" : "btn-outline-green"}

                    id="btnContenido2" onClick={() => { setShowAlliesFAQ(true); setShowClientsFAQ(false) }}>Para aliados</button>
            </div>

            <div className="row w-100 my-5">
                <div className="col-sm-6">
                    <div className="mt-5">
                        <div id="contenido1" className="content" style={{ display: showClientsFAQ ? "block" : "none" }}>
                            <div className="accordion-container">

                                <div className="container-special-orange">
                                    <button onClick={() => { openedAc === 1 ? setOpenedAc(0) : setOpenedAc(1) }}
                                        className={`btn-special accordion ${openedAc === 1 ? "open active" : ""}`}>
                                        <span className="fw-bold">¿Qué es Meddipay?</span>

                                    </button>
                                    <div className="panel" style={{ maxHeight: openedAc === 1 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            Meddipay es una plataforma digital que te ofrece planes de pagos simples y transparentes con los mejores proveedores de salud, bienestar y belleza tales como tratamientos dentales, procedimientos oculares, dermatología, tratamientos de fertilidad, cirugías plástica y veterinaria.
                                        </p>
                                    </div>

                                </div>
                                <div className="container-special-orange">
                                    <button onClick={() => { openedAc === 2 ? setOpenedAc(0) : setOpenedAc(2) }}
                                        className={`btn-special accordion ${openedAc === 2 ? "open active" : ""}`}>
                                        <span className="fw-bold">¿Qué es el cupo y cómo puedo solicitarlo?</span>
                                    </button>
                                    <div className="panel" style={{ maxHeight: openedAc === 2 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            El cupo es el total de dinero que te dará Meddipay para que puedas realizarte los tratamientos que desees. Solicita tu cupo desde la comodidad de tu casa, 100% digital, sin papeleos y tendrás respuesta a tu solicitud en minutos. Una vez aprobado tu cupo, podrás usarlo en cualquiera de las clínicas de nuestros aliados.
                                        </p>

                                    </div>
                                </div>
                                <div className="container-special-orange">
                                    <button onClick={() => { openedAc === 3 ? setOpenedAc(0) : setOpenedAc(3) }}
                                        className={`btn-special accordion ${openedAc === 3 ? "open active" : ""}`}>
                                        <span className="fw-bold">¿Cuáles  son los requisitos para solicitar mi cupo con Meddipay?</span>
                                    </button>
                                    <div className="panel" style={{ maxHeight: openedAc === 3 ? "800px" : null, maxWidth: "750px" }}>

                                        Realiza tu solicitud fácil, rápido y seguro

                                        <ul>
                                            <li>Ser mayor de 18 años.</li>
                                            <li>Tener a la mano tu cedula de ciudadanía. </li>
                                            <li>Tener un dispositivo con buena conexión a internet y que disponga de cámara.  </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="container-special-orange">
                                    <button onClick={() => { openedAc === 4 ? setOpenedAc(0) : setOpenedAc(4) }}
                                        className={`btn-special accordion ${openedAc === 4 ? "open active" : ""}`}>
                                        <span className="fw-bold">¿Qué tasa de interés se le aplicará a mi plan de pagos?</span>
                                    </button>
                                    <div className="panel" style={{ maxHeight: openedAc === 4 ? "800px" : null, maxWidth: "750px" }}>

                                        <p className="p-4">
                                            Estas varían según los acuerdos con nuestros aliados y tu perfil crediticio, siendo desde el 0% hasta la tasa de usura vigente para el mes en curso.
                                        </p>

                                    </div>
                                </div>
                                <div className="container-special-orange">
                                    <button onClick={() => { openedAc === 5 ? setOpenedAc(0) : setOpenedAc(5) }}
                                        className={`btn-special accordion ${openedAc === 5 ? "open active" : ""}`}>
                                        <span className="fw-bold">¿Dónde y cómo puedo realizar los pagos?</span>
                                    </button>
                                    <div className="panel" style={{ maxHeight: openedAc === 5 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            Realiza tus pagos <a target="_blank" rel="noreferrer" href="/payment">aquí</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="container-special-orange">
                                    <button onClick={() => { openedAc === 6 ? setOpenedAc(0) : setOpenedAc(6) }}
                                        className={`btn-special accordion ${openedAc === 6 ? "open active" : ""}`}>
                                        <span className="fw-bold">¿Cómo puedo conocer el estado de mi cuenta?</span>
                                    </button>
                                    <div className="panel" style={{ maxHeight: openedAc === 6 ? "800px" : null, maxWidth: "750px" }}>
                                        Puedes conocer esta y mucha más información de tus planes de pago por cualquiera de los siguientes canales:
                                        <ul>
                                            <li>Accediendo a nuestra web app: <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_CHAN}>app.meddipay.com.co</a></li>
                                            <li>Escríbenos a WhatsApp: <a target="_blank" rel="noreferrer" href={"https://wa.me/" + encodeURI("+573012051542")}>+57 3012051542</a></li>
                                            <li>Escríbenos un correo: <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a></li>
                                        </ul>
                                    </div>
                                </div>




                            </div>

                            <div style={{ textAlign: "center", marginTop: "10px" }}>
                                <p onClick={goToFAQClients} style={{ cursor: "pointer" }}>Tengo más preguntas</p>
                            </div>
                        </div>

                        <div id="contenido2" className="content" style={{ display: showAlliesFAQ ? "block" : "none" }}>
                            <div className="accordion-container">

                                <div className="container-special-green">
                                    <button onClick={() => { openedAc === 1 ? setOpenedAc(0) : setOpenedAc(1) }}
                                        className={`btn-special accordion ${openedAc === 1 ? "open active" : ""}`}>
                                        <span className="fw-bold text-black">¿Qué es Meddipay?</span>

                                    </button>
                                    <div className="panel text-black" style={{ maxHeight: openedAc === 1 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            Meddipay te apoya ofreciéndole a tus pacientes alternativas de pago y así te ayuda a incrementar tus ventas, recibiendo tus pagos de forma segura y puntual.
                                        </p>
                                    </div>

                                </div>
                                <div className="container-special-green">
                                    <button onClick={() => { openedAc === 2 ? setOpenedAc(0) : setOpenedAc(2) }}
                                        className={`btn-special accordion ${openedAc === 2 ? "open active" : ""}`}>
                                        <span className="fw-bold text-black">Me quiero asociar con Meddipay ¿Qué debo hacer?</span>
                                    </button>
                                    <div className="panel text-black" style={{ maxHeight: openedAc === 2 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            Para ser un aliado con Meddipay ponte en contacto a través del siguiente enlace <a href="contact">ir a contacto</a>, indícanos nombre comercial, el sector al que pertenece tu negocio y descríbenos brevemente el servicio que deseas ofrecer o dudas al respecto.
                                        </p>

                                    </div>
                                </div>
                                <div className="container-special-green">
                                    <button onClick={() => { openedAc === 3 ? setOpenedAc(0) : setOpenedAc(3) }}
                                        className={`btn-special accordion ${openedAc === 3 ? "open active" : ""}`}>
                                        <span className="fw-bold text-black">¿Cómo funciona Meddipay?</span>
                                    </button>
                                    <div className="panel text-black" style={{ maxHeight: openedAc === 3 ? "800px" : null, maxWidth: "750px" }}>

                                        <p className="p-4">
                                            Como aliado, te daremos acceso exclusivo a nuestra Web App, desde la cual podrás enviar las invitaciones de los pacientes interesados en solicitar financiación de tus tratamientos con su respectivo presupuesto, una vez el paciente tenga la financiación aprobada y firmada, se procederá a hacer efectivo el desembolso a la clínica y podrás comenzar con el tratamiento del paciente.
                                        </p>
                                    </div>
                                </div>
                                <div className="container-special-green">
                                    <button onClick={() => { openedAc === 4 ? setOpenedAc(0) : setOpenedAc(4) }}
                                        className={`btn-special accordion ${openedAc === 4 ? "open active" : ""}`}>
                                        <span className="fw-bold text-black">¿Qué son y cómo funcionan las invitaciones?</span>
                                    </button>
                                    <div className="panel text-black" style={{ maxHeight: openedAc === 4 ? "800px" : null, maxWidth: "750px" }}>

                                        <p className="p-4">

                                            Para que el paciente pueda disponer de su cupo, necesita previamente que tú le envíes una invitación. En la invitación deberás indicar: la cédula del paciente, datos de contacto (opcionales), el importe del tratamiento y el importe a financiar.

                                            De esta forma, el paciente al ingresar a la plataforma de Meddipay, gestionará su cupo, podrá acceder a dicha invitación y continuar con el proceso de financiación.
                                        </p>

                                    </div>
                                </div>
                                <div className="container-special-green">
                                    <button onClick={() => { openedAc === 5 ? setOpenedAc(0) : setOpenedAc(5) }}
                                        className={`btn-special accordion ${openedAc === 5 ? "open active" : ""}`}>
                                        <span className="fw-bold text-black">¿Cuándo será desembolsado el monto financiado de mi paciente?</span>
                                    </button>
                                    <div className="panel text-black" style={{ maxHeight: openedAc === 5 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            Una vez el cliente haya firmado contrato con Meddipay el desembolso podrá tardar entre cinco y diez días hábiles.
                                        </p>
                                    </div>
                                </div>
                                <div className="container-special-green">
                                    <button onClick={() => { openedAc === 6 ? setOpenedAc(0) : setOpenedAc(6) }}
                                        className={`btn-special accordion ${openedAc === 6 ? "open active" : ""}`}>
                                        <span className="fw-bold text-black">¿Qué tasa de interés maneja Meddipay?</span>
                                    </button>
                                    <div className="panel text-black" style={{ maxHeight: openedAc === 6 ? "800px" : null, maxWidth: "750px" }}>
                                        <p className="p-4">
                                            Antes de firmar el contrato, el paciente conocerá de primera mano que tasa de interés tendrá su plan de pagos; estas varían según los acuerdos con nuestros aliados, siendo desde el 0% hasta la tasa de usura vigente para el mes en curso.
                                        </p>
                                    </div>
                                </div>




                            </div>
                            <div style={{ textAlign: "center", marginTop: "10px" }}>
                                <p onClick={goToFAQAllies} style={{ cursor: "pointer" }}>Tengo más preguntas</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-sm-6 d-flex justify-content-center align-items-center" style={{ zIndex: "-999" }}>
                    <img
                        className="img-fluid d-none d-sm-block"
                        src="assets/images/img/image-4.svg"
                        alt="person"
                    />
                </div>


            </div>

        </section>

        <section>
            <div className="bg-mp-gray py-5">
                <div className="row w-100">
                    <div
                        className="col-sm-6 text-center mb-5 d-flex justify-content-center flex-column align-items-center"
                    >
                        <div className="d-flex justify-content-center py-2" style={{ padding: "20px" }}>
                            <p className="fs-header-secondary text-center" style={{ lineHeight: "45px" }}>
                                ¿Listo para<br className="salto" />  empezar?
                            </p>
                        </div>

                        <div className="d-flex justify-content-center flex-wrap gap-5">
                            <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToChan}>Soy paciente</button>
                            <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={goToAgora}>Soy aliado</button>
                        </div>
                    </div>

                    <div
                        className="col-sm-6 text-center mb-5 d-flex justify-content-center flex-column align-items-center"
                    >
                        <div className="d-flex justify-content-center">
                            <p className="fs-header-secondary text-center">¿Hablamos?</p>
                        </div>
                        <p className="fs-5">
                            Si tienes cualquier duda adicional estaremos encantados de
                            atenderte.
                        </p>
                        <div className="d-flex justify-content-center gap-5">
                            <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}


export default AboutUs;