import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import WompiService from '../services/WompiService';


const PaymentResult = ({ goToChan, goToAgora }) => {
    const navigate = useNavigate();
    const { GetTransaction } = WompiService();
    const [transactionResult, setTransactionResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const moneyLocale = Intl.NumberFormat('es-CO');
    const [reference, setReference] = useState(null);

    useEffect(() => {


        window.scrollTo(0, 0)


        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        setLoading(true);
        GetTransaction(id).then(res => {
            setLoading(false);

            if (res.status === 200) {
                setTransactionResult(res.data.data)
                setReference(res.data.data.reference.split("-")[0])

                if (res.data.data.status === "APPROVED") {
                    document.title = "Pago exitoso";
                }
                else if (res.data.data.status === "DECLINED" || res.data.data.status === "ERROR") {
                    document.title = "Pago fallido";
                }
                else {
                    document.title = "Pago pendiente";
                }
            }
            else {
                navigate("/");
            }

        })


    }, []);

    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    const goToPayment = () => {
        navigate("/payment?reference=" + reference);
        window.scrollTo(0, 0)
    }

    return (<><Container style={{ marginTop: "20px", marginBottom: "30px" }}>
        <Row style={{ display: transactionResult && transactionResult.status === "APPROVED" ? "flex" : "none" }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img style={{ width: "70px" }}
                    src="assets/images/img/blue_check.svg"
                    alt="payment" />
                <div style={{ margin: "20px auto" }} className="payment-text">
                    <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "#00A9EB" }}>exitoso</span></h1>
                    <p style={{ textAlign: "left", marginTop: "20px" }} >
                        Gracias por tu compromiso, continúa disfrutando de todas las ventajas que Meddipay te ofrece.
                        <br />
                        A continuación encontraras los detalles de la transacción que también te servirá como comprobante de pago.
                    </p>
                    <p style={{ fontWeight: "bold" }}>ID Pago<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.id}</span></p>
                    <p style={{ fontWeight: "bold" }}>Valor<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>${moneyLocale.format(transactionResult?.amount_in_cents / 100)}</span></p>
                    <p style={{ fontWeight: "bold" }}>Fecha<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.finalized_at}</span></p>
                    <p style={{ fontWeight: "bold" }}>Referencia Interna<br />
                        <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.reference}</span></p>
                </div>

            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img className="img-fluid"
                    src="assets/images/img/payment.svg"
                    alt="payment" />
                <p>  Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                <br />
                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
            </Col>
        </Row>
        <Row style={{ display: transactionResult && (transactionResult.status === "DECLINED" || transactionResult.status === "ERROR") ? "flex" : "none" }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img style={{ width: "70px" }}
                    src="assets/images/img/delete.svg"
                    alt="payment" />
                <div style={{ margin: "20px auto" }} className="payment-text">
                    <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "red" }}>fallido</span></h1>
                    <p style={{ textAlign: "left", marginTop: "20px" }} >
                        Ha ocurrido un error a la hora de procesar el pago. A continuacion encontraras mas detalles. </p>
                    <p style={{ fontWeight: "bold" }}>ID Pago<br />
                        <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.id}</span></p>
                    <p style={{ fontWeight: "bold" }}>Mensaje<br />
                        <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.status_message}</span></p>
                    <p style={{ fontWeight: "bold" }}>Fecha<br />
                        <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.finalized_at}</span></p>
                    <p style={{ fontWeight: "bold" }}>Referencia Interna<br />
                        <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.reference}</span></p>

                    <br />
                    <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={goToPayment}>Reintentar</button>
                </div>

            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img className="img-fluid"
                    src="assets/images/img/payment.svg"
                    alt="payment" />
                <p>  Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                <br />
                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
            </Col>
        </Row>

        <Row style={{ display: transactionResult && transactionResult.status === "PENDING" ? "flex" : "none" }}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img style={{ width: "70px" }}
                    src="assets/images/img/pending_orange.svg"
                    alt="payment" />
                <div style={{ margin: "20px auto" }} className="payment-text">
                    <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "#ff9e25" }}>pendiente</span></h1>
                    <p style={{ textAlign: "left", marginTop: "20px" }} >
                        Has generado el proceso para realizar el pago pero el pago todavía no se ha realizado. Por favor, sigue las instrucciones que se te han proporcionado para completar el pago.
                        <br />         <br />
                        No cierres esta pagina, una vez realizado el pago, prueba a actualizar para saber el estado final del pago.
                    </p>
                    <p style={{ fontWeight: "bold" }}>Forma de pago<br />
                        <span style={{ color: "#ff9e25", fontWeight: "bold" }}>Consignación Bancolombia</span></p>
                    <p style={{ fontWeight: "bold" }}>Valor<br />
                        <span style={{ color: "#ff9e25", fontWeight: "bold" }}>${moneyLocale.format(transactionResult?.amount_in_cents / 100)}</span></p>
                    <p style={{ fontWeight: "bold" }}>Convenio<br />
                        <span style={{ color: "#ff9e25", fontWeight: "bold" }}>{transactionResult?.payment_method?.extra?.business_agreement_code}</span></p>
                    <p style={{ fontWeight: "bold" }}>Referencia convenio<br />
                        <span style={{ color: "#ff9e25", fontWeight: "bold" }}>{transactionResult?.payment_method?.extra?.external_identifier}</span></p>
                    <p style={{ fontWeight: "bold" }}>Fecha proceso<br />
                        <span style={{ color: "#ff9e25", fontWeight: "bold" }}>{transactionResult?.created_at}</span></p>

                </div>

            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                <img className="img-fluid"
                    src="assets/images/img/payment.svg"
                    alt="payment" />
                <p>  Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                <br />
                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
            </Col>
        </Row>
    </Container>

        {loading &&
            (
                <div className="my-loader">
                    <div className="my-loader-container">
                        <img src="assets/images/img/MeddipayLoaderEllipsis.gif" alt="loader"></img>

                    </div>
                </div>
            )}
    </>
    )
}


export default PaymentResult;